:root {
  --glow: rgba(0,0,0,0.25);
  --glow2: rgba(0,0,0,0.5);
  --colorin-delay: 100ms;
  --rainbowthrob-duration: 2s;
  --home-bg-transition: 2s;
  --bubble: calc(min(470px, calc(50vw - 10px)) / 4);
}

body {
  font-family: Silkscreen !important;
  font-size: 14px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: SilkscreenBold !important;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.ui.modal > .header {
  font-family: Silkscreen !important;
}

@font-face {
  font-family: Silkscreen;
  src: url("fonts/Silkscreen-Regular.otf") format("opentype");
  descent-override: 25%;
}

@font-face {
  font-family: SilkscreenBold;
  src: url("fonts/Silkscreen-Bold.otf") format("opentype");
}

.burnt {
  filter: brightness(50) saturate(0);
}

.drawin {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  animation: aaflash 700ms forwards 1 cubic-bezier(.25,.46,.45,.94);
  backdrop-filter: brightness(25) saturate(0);
  z-index: 5;
}

.homeflash {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  animation: flash 500ms forwards 1 cubic-bezier(.25,.46,.45,.94);
  backdrop-filter: brightness(25) saturate(0);
  z-index: 5;
  pointer-events: none;
}

.delay-1s {
  animation-delay: 100ms;
}

.delay-2s {
  animation-delay: 250ms;
}

@keyframes aaflash {
  80% {
    backdrop-filter: brightness(5) saturate(0);
  }
  99% {
    backdrop-filter: brightness(1) saturate(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.hometextflash {
  opacity: 0.3;
  animation: 500ms hometextflash forwards cubic-bezier(.25,.46,.45,.94);
  filter: blur(8px);
}

@keyframes hometextflash {
  0% {
    filter: blur(10px);
  }
  50% {
    filter: blur(0px);
  }
  100% {
    opacity: 1;
    filter: blur(0px);
  }
}

.delay-3s {
  animation-delay: 500ms !important;
}

.delay-4s {
  animation-delay: 750ms !important;
}

.rainbow {
  animation: rainbow 3s forwards infinite linear;
}

@keyframes rainbow {
  0% {
    filter: hue-rotate(0);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.whiteoverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgb(255,255,255,0.85), rgb(255,255,255,0.9));
}

.fadeout {
  animation: 750ms fadeout 1 forwards;
}

@keyframes fadeout {
  80% {
    opacity: 0.1;
  }
  100% {
    transform: scale(1.75);
    filter: blur(65px);
    opacity: 0;
    display: none;
  }
}

img {
  max-width: 100%;
  max-height: 100%;
}

.fakelink, .afakelink {
  cursor: pointer;
}

.afakelink {
  color: #4183c4;
  text-decoration: underline;
}

a {
  text-decoration: underline !important;
}

.pp-tablediv {
  position: relative;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.7) 95%, rgba(255,255,255,1) 100%);
  padding-left: 3rem;
  padding-right: 3rem;
  animation: 200ms showslow forwards;
  animation-delay: 550ms;
  opacity: 0;
  filter: blur(50px);
}

@keyframes showslow {
  100% {
    opacity: 1;
    filter: blur(0px);
  }
}

.pushable > .pusher::after {
  background-color: transparent !important;
  backdrop-filter: grayscale(0.5) blur(10px);
  background-color: transparent;
}

.rainbowboi {
  animation: 8s zoomoutboi forwards ease-out;
  animation-delay: 1.2s;
  transform-origin: 57.5% 42.9%;
  transform: scale(25);
  image-rendering: pixelated;
  opacity: 0;
  filter: brightness(1.1) grayscale(1) contrast(1.7) blur(0px);
}

/* 
 * 2.5 full head
 * 5 half head
 * 15 half eye
 * 25 full eye
 *  filter: brightness(5) grayscale(1) blur(0px) hue-rotate(0deg);
 */

@keyframes zoomoutboi {
  0% {
    opacity: 0;
    filter: brightness(1.1) grayscale(1) contrast(1.7) blur(0px);
    transform: scale(25);
  }
  5% {
    opacity: 1;
    filter: brightness(4) grayscale(1) contrast(1.4) blur(0.5px);
    transform: scale(35);
  }
  10% {
    filter: brightness(4) grayscale(1) contrast(1.4) blur(0.5px);
    transform: scale(25);
  }
  15% {
    filter: brightness(3) grayscale(1) contrast(1.2) blur(0);
    transform: scale(25);
  }
  25% {
    filter: brightness(1) grayscale(1);
    transform: scale(5);
  }
  30% {
    filter: brightness(1) grayscale(1);
    transform: scale(5);
  }
  45% {
    filter: brightness(1) grayscale(0);
    transform: scale(2);
  }
  50% {
    filter: brightness(1) grayscale(0);
    transform: scale(2);
  }
  100% {
    filter: brightness(1) grayscale(0);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoomoutboirotate {
  0% {
    filter: brightness(1.1) grayscale(0) contrast(1.7) blur(0px);
    transform: scale(25) rotate(380deg);
  }
  20% {
    filter: brightness(4) grayscale(1) contrast(1.4) blur(0.5px);
    transform: scale(45) rotate(360deg - 18%);
  }
  40% {
    filter: brightness(4) grayscale(1) contrast(1.4) blur(0.5px);
    transform: scale(35) rotate(360deg - 40%);
  }
  60% {
    filter: brightness(1) grayscale(0.5);
    transform: scale(20) rotate(360deg - 60%);
  }
  80% {
    filter: brightness(1) grayscale(0.25);
    transform: scale(4.5) rotate(360deg - 80%);
  }
  90% {
    filter: brightness(1) grayscale(0);
    transform: scale(2.5) rotate(360deg - 90%);
  }
  100% {
    filter: brightness(1) grayscale(0);
    transform: scale(1) rotate(0deg);
  }
}

.zoominpup {
  transform: scale(1);
  transform-origin: 50% 40.5%;
  overflow: hidden;
  animation: 1.5s zoominpup forwards ease-out;
}

@keyframes zoominpup {
  60% {
    opacity: 1;
    filter: brightness(5) saturate(0);
  }
  100% {
    transform: scale(10);
    filter: brightness(5) saturate(0) blur(3px);
    opacity: 0;
  }
}

.page-pupgrade-btn {
  position: absolute;
  top: -25px;
}

.pupgrade-btn {
  opacity: 0%;
  margin-bottom: 25px !important;
  background-color: rgba(255,128,0,1) !important;
  animation: 333ms pupgrade-btn 500ms forwards, 1.5s rainbow infinite linear;
}

@keyframes pupgrade-btn {
  0% {
    transform: scale(0);
  }
  25% {
    opacity: 1;
  }
  75% { 
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.txnicon {
  aspect-ratio: 1;
  display: inline-flex;
  width: 24px;
  margin-right: 4px;
  justify-content: center;
  align-items: center;
}

.rotating {
  animation: 2s rotate infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.faded {
  opacity: 0.5;
}

.bold {
  font-weight: bold;
}

.fontbold {
  font-family: SilkscreenBold;
}

.grayscale {
  animation: 1.5s togray forwards;
}

@keyframes togray {
  100% {
    filter: saturate(0);
  }
}

.error {
  font-size: 120%;
  font-weight: bold;
  max-width: 300px;
  word-break: break-word;
}

.inline-mintable-badge {
  display: inline-block;
  border-radius: 100%;
  background-image: url(assets/images/bois-48.png);
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  animation: var(--rainbowthrob-duration) rainbowthrob infinite linear;
}

.mintable-badge {
  position: absolute;
  top: 1px;
  right: 1px;
  background: rgba(255,255,255,0);
  border-radius: 100%;
  background-image: url(assets/images/bois-48.png);
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  animation: var(--rainbowthrob-duration) rainbowthrob infinite linear;
}

.big-mintable-badge-2 {
  background-image: url(assets/images/bois-48.png);
  background-size: 24px 24px;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 3px;
  right: 25px;
  color: rgba(255,128,0,1);
  animation: var(--rainbowthrob-duration) rainbowthrob infinite linear;
}

.big-mintable-badge {
  position: absolute;
  top: -1px;
  left: 25px;
  color: rgba(255,128,0,1);
  animation: var(--rainbowthrob-duration) rainbowthrob infinite linear;
}

div:nth-child(5n) > .mintable-badge {
  animation-delay: 0s;
}

div:nth-child(5n+1) > .mintable-badge {
  animation-delay: calc(0.2 * var(--rainbowthrob-duration));
}

div:nth-child(5n+2) > .mintable-badge {
  animation-delay: calc(0.4 * var(--rainbowthrob-duration));
}

div:nth-child(5n+3) > .mintable-badge {
  animation-delay: calc(0.6 * var(--rainbowthrob-duration));
}

div:nth-child(5n+4) > .mintable-badge {
  animation-delay: calc(0.8 * var(--rainbowthrob-duration));
}


@keyframes rainbowthrob {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.mono {
  font-family: monospace;
}

.whitelink {
  text-decoration: underline;
}

.ui.button {
  font-family: Silkscreen !important;
}

.ui.header {
  font-family: Silkscreen !important;
}

.menu-arrow {
  visibility: hidden;
}

.menu-row:hover .menu-arrow {
  animation: 300ms rainbowsepia infinite linear;
  font-size: 3rem;
  visibility: visible;
}

@keyframes rainbowsepia {
  0% {
    filter: sepia(2) hue-rotate(0);
  }
  100% {
    filter: sepia(2) hue-rotate(360deg);
  }
}

.fliph {
  transform: scale(-100%, 100%);
}

.menu-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-row h3 {
  font-size: 3rem !important;
  color: rgba(0,0,0,0.7);
  margin: 0.5rem;
  padding: 0;
}

.menu-row:hover h3 {
  color: rgba(255,186,0,1);
  animation: 1000ms rainbow infinite linear;
}

.menu-arrow img {
  width: 48px;
  height: 48px;
}

.menu-row:hover {
  animation: 100ms scaledrop infinite linear;
}

@keyframes scaledrop {
  100% {
    transform: scale(1.06);
  }
}

.home-image-container {
  transition: background-color var(--home-bg-transition);
  position: relative;
  overflow: hidden;
}

.relative {
  position: relative;
}


.h1-title {
  animation: 1s h1 infinite ease-in-out;
  animation-delay: var(--colorin-delay);
  mix-blend-mode: color-burn;
  transition: color var(--home-bg-transition);
  text-align: center;
  font-size: 5rem;
  line-height: 4.5rem;
  filter: brightness(0.95);
  text-shadow:
    1px 2px 2px var(--glow),
    1px 2px 4px var(--glow),
    1px 2px 8px var(--glow),
    1px 2px 16px var(--glow);
}

@keyframes h1 {
  50% {
    filter: brightness(0.9) contrast(1.2);
    text-shadow:
      1px 2px 2px var(--glow),
      1px 2px 6px var(--glow),
      1px 2px 12px var(--glow),
      1px 2px 18px var(--glow);
  }
}

.homepup, .home-image-container {
  width: calc(min(470px, 50vw - 10px));
  height: calc(min(470px, 50vw - 10px));
}

.homepup {
  transition: left 500ms;
  left: 0;
  overflow: visible;
}

.step-0 .homepup,
.step-0 .homeboi,
.step-1 .homeboi,
.step-2 .homeboi,
.step-3 .homeboi,
.step-4 .homeboi {
  display: none;
}

.step-5 .homepup {
  animation: 666ms jjump infinite step-start;
}
.step-6 .homepup,
.step-7 .homepup
{
  animation: 333ms jjump infinite step-start;
}

.homeboi {
  filter: blur(15px) contrast(1.5);
  transition: 750ms filter, 250ms opacity;
  opacity: 0.5;
}

.step-5 .homeboi,
.step-6 .homeboi {
  animation: 333ms ssshake infinite linear;
}

.step-9 .homeboi,
.step-8 .homeboi,
.step-7 .homeboi {
  filter: blur(0) contrast(1);
  opacity: 1;
}

/* .step-2 .homepup {
  left: calc(0px - min(470px, calc(50vw - 10px)) / 2);
} */

.step-2 .homepup {
  animation: 3s fliphhh forwards step-start;
}

@keyframes fliphhh {
  25% {
    transform: scale(-1, 1) translate(-20%, 0);
  }
  50% {
    transform: scale(1, 1);
  }
  75% {
    transform: scale(-1, 1) translate(-20%, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

.homebubble {
  position: absolute;
  background: url(assets/images/chat.svg);
  background-size: var(--bubble) var(--bubble);
  top: calc(0px - var(--bubble)/5);
  left: calc(100% - var(--bubble) / 2.2);
  width: var(--bubble);
  height: var(--bubble);
  font-size: calc(var(--bubble) / 2.5);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: calc(var(--bubble) / 4.5);
  transform-origin: bottom left;
  transform: scale(0);
  opacity: 0;
  transition: 150ms transform, 50ms opacity;
}

.step-3 .homebubble,
.step-5 .homebubble,
.step-8 .homebubble {
  transform: scale(1);
  opacity: 1;
}

.home-images-container {
  width: 100%;
  display: flex;
  justify-content: center;
  /* border-top: 15px rgba(0, 0, 0, 0.15) solid;
  border-bottom: 15px rgba(0, 0, 0, 0.15) solid; JORGE */
}

.colorin {
  transition: background-color var(--home-bg-transition);
  animation: 500ms colorin forwards ease-out;
  animation-delay: var(--colorin-delay);
  filter: saturate(0) blur(8px) brightness(0.55) contrast(1.2);
  filter: saturate(0) blur(4px) brightness(1.75);
}

@keyframes colorin {
  80% {
    filter: saturate(1.5) blur(0px);
  }
  100% {
    filter: saturate(1) blur(0px);
  }
}

.homegradient {
  width: 100%;
  background-blend-mode: screen;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.40) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.homegradientbottom {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0) 75%
  );
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pupgrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
}

.pupgrid > * {
  border: 0.5px transparent solid;
}

#fuuuuck {
  background-color: rgba(0,0,0,0.80) !important;
  border-radius: 2px;
  mix-blend-mode: difference;
  margin-top: 2rem;
  margin-bottom: 4rem !important;
}

.pup-page-flex {
  width: 100%;
  padding: 20px;
  padding-bottom: 20px;
  justify-content: space-around;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
}

.pill-container {
  margin-right: 2px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
  .pp-tablediv {
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: column-reverse;
    & .pupgrade-btn {
      margin-top: 25px !important;
      margin-bottom: 0px !important;
    }
  }
  .pill-container {
    flex-direction: column;
    margin-right: 1px;
  }
  .pup-page-flex {
    flex-direction: column;
    align-items: center;
  }
  .pup-page-flex table:nth-child(2) {
    margin-top: 10px;
  }
  .pup-page-flex table {
    width: 90%;
  }
}

.offsetdown {
  position: relative;
  transform: translate(0%, 100%);
}

.footerlink {
  color: rgba(0, 0, 0, 0.75);
}

.footerlink:hover {
  color: rgba(0, 0, 0, 1);
  transform: scale(1.1);
}

.header-logo {
  opacity: 0.8;
  animation: 60s rainbowthrob infinite linear;
}

.modalgradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 175px;
  background: linear-gradient(180deg, black, rgba(0,0,0,0.35), rgba(0, 0, 0, 0));
  mix-blend-mode: overlay;
  pointer-events: none;
  z-index: 100;
}

.progitem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.progitem:last-child {
  margin-bottom: 0px;
}

.shake {
  position: relative;
  animation: 333ms sshake infinite linear;
}

@keyframes ssshake {
  0% {
    top:-4px;
    left: -4px;
  }
  45% {
    top: 4px;
    left: 4px;
  }
  50% {
    top: 4px;
    left: -4px;
  }
  75% {
    top: -4px;
    left: 4px;
  }
  100% {
    top: -4px;
    left: -4px;
  }
}

@keyframes jjump {
  0% {
    top: -2px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: -2px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: -2px;
  }
}

@keyframes sshake {
  0% {
    top:-2px;
    left: -2px;
  }
  25% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: -2px;
    left: 2px;
  }
  100% {
    top: -2px;
    left: -2px;
  }
}

.abs-left-middle {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-150%, -50%);
}

.infomodal p {
  text-align: center;
}

.infomodal .icon.large {
  margin-top: 20px;
  font-size: 36pt;
  margin-bottom: 4px;
}

.preparing-btn {
  display: flex !important;
}

#pera-wallet-sign-txn-toast-wrapper, #defly-wallet-sign-txn-toast-wrapper {
  display: none;
}
